.light {
    .blogform {
        form {
            width: 45%;
            border: 1px solid #cccccc;
            border-radius: 5px;
            padding: 5px;

            .form-group {

                input {
                    border: 1px solid #cccccc;
                    border-radius: 0 20px;
                    padding: 12px 20px;
                    outline: none;
                }

                label{
                    color: $grayColor
                }
            }

            button {
                @include button;
                background: $linearColor;
                border: none;

                &:hover {
                    background: $linearColor;
                }
            }
        }
    }
}

.dark {
    h1{
        color: $lightColor;
    }
    .blogform {
        form {
            width: 45%;
            border: 1px solid $grayColor;
            border-radius: 5px;
            padding: 5px;

            .form-group {

                input {
                    background-color: $darkColor;
                    color: #cccccc;
                    border: 1px solid $grayColor;
                    border-radius: 0 20px;
                    padding: 12px 20px;
                    outline: none;
                }

                label{
                    color: #cccccc
                }
            }

            button {
                @include button;
                background: $linearColor;
                border: none;

                &:hover {
                    background: $linearColor;
                }
            }
        }
    }
}

@media(max-width: 992px) {
    .light{
        .blogform form {
            width: 100%;
        }
    }

    .dark{
        .blogform form {
            width: 100%;
        }
    }
}
.light {
    .faqpage {
        .heading {
            background-color: $darkColor;
            border-bottom: 1px solid #cccccc;

            a {
                .svg-ic {
                    color: $lightColor;
                    border: 1px solid;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;

                    svg path {
                        fill: $lightColor;
                    }
                }

                span {
                    font-size: $secondFontSize;
                    letter-spacing: .6px;
                    letter-spacing: .6px;
                    color: $lightColor;
                }
            }

            h5 {
                color: #0098ff;
            }

            p {
                font-size: $secondFontSize;
                letter-spacing: .6px;
                line-height: 23px;
                color: $grayColor;
                width: 50%;
            }
        }

        .faq {
            h2 {
                font-size: 41px;

                b {
                    background: -webkit-linear-gradient(93.25deg, #0098ff 4.45%, #7b35c8 93.88%);
                    background-clip: text;
                    color: transparent;
                }
            }

            .accordion {
                border: 1px solid $grayColor;
                font-size: $firstFontSize;
                color: $grayColor;

                .accordion-header button {
                    border-bottom: 1px solid $grayColor;
                    font-size: $firstFontSize;
                    color: $grayColor;

                    &:focus {
                        box-shadow: none !important;
                        background: transparent !important;
                    }
                }
            }
        }
    }
}

.dark {
    .faqpage .faq {
        h2 {
            color: $lightColor;
        }

        .accordion .accordion-header button {
            background-color: $darkColor;
            color: #ccc;
        }
    }
}

@media(max-width:992px) {
    .light {
        .faqpage {
            .heading {
                p {
                    width: 100%;
                }
            }
        }
    }
}
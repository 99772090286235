.light {
    .productpage {
        .heading {
            background-color: $darkColor;
            border: 1px solid;

            a {
                .svg-ic {
                    color: $lightColor;
                    border: 1px solid;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;

                    svg path {
                        fill: $lightColor;
                    }
                }

                span {
                    font-size: $secondFontSize;
                    letter-spacing: .6px;
                    color: $lightColor;
                }
            }

            p {
                color: #0098ff;
            }
        }

        .laptop{
            .products {
                .filter {
                    font-size: $firstFontSize;
    
                    a {
                        @include link;
                    }
    
                    .brand button {
                        background: transparent;
                    }
                }
    
                .product .header {
                    .left a {
                        color: $darkColor;
                        font-size: 14px;
                        font-weight: 600;
                    }
    
                    .right {
                        span {
                            font-weight: 600;
                            font-size: 14px;
                        }
    
                        select {
                            font-size: 14px;
                            outline: 0;
                        }
                    }
                }
    
                .pagination {
                    .page-item {
                        a {
                            background-color: transparent;
                            color: $darkColor;
                            border: 1px solid $darkColor;
                            border-radius: 50%;
                        }
    
                        .pg-active {
                            border: 1px solid;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }

        .mobile{
            display: none;
            .products {
                .filter {
                    font-size: $firstFontSize;
    
                    a {
                        @include link;
                    }
    
                    .brand button {
                        background: transparent;
                    }
                }
    
                .product .header {
                    .left a {
                        color: $darkColor;
                        font-size: 14px;
                        font-weight: 600;
                    }
    
                    .right {
                        span {
                            font-weight: 600;
                            font-size: 14px;
                        }
    
                        select {
                            font-size: 14px;
                            outline: 0;
                        }
                    }
                }
    
                .pagination {
                    .page-item {
                        a {
                            background-color: transparent;
                            color: $darkColor;
                            border: 1px solid $darkColor;
                            border-radius: 50%;
                        }
    
                        .pg-active {
                            border: 1px solid;
                            border-radius: 50%;
                        }
                    }
                }
            }
        }
    }
}

.dark {
    .productpage .products {
        background-color: $darkColor;

        .filter {
            color: $lightColor;

            .brand button {
                color: $lightColor;
            }
        }

        .product .header {
            .left {
                span {
                    color: $lightColor;
                }

                a {
                    color: $lightColor;
                }
            }

            .right span {
                color: $lightColor;
            }
        }

        .pagination .page-item a {
            background-color: transparent;
            color: $lightColor;
            border: 1px solid;
        }
    }
}


@media(max-width: 992px) {
    .productpage .products .product .carousel {
        .title a {
            font-size: 16px !important;
        }

        .footer {
            .price .newprice {
                font-size: 15px !important;
            }

            button {
                padding: 5px 19px !important;
                border: none;
                font-size: 12px;
            }
        }
    }
}

@media(max-width: 768px){
    .laptop{
        display: none;
    }

    .mobile{
        display: block !important;
    }
}

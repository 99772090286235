.light {
    .dashboardpage {
        .heading {
            background: $darkColor;
            border-bottom: 1px solid;


            a {
                .svg-ic {
                    color: $lightColor;
                    border: 1px solid;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;

                    svg path {
                        fill: $lightColor;
                    }
                }

                span {
                    font-size: $secondFontSize;
                    letter-spacing: .6px;
                    letter-spacing: .6px;
                    color: $lightColor;
                }
            }

            h5 {
                color: #0098ff;
            }

            span {
                font-size: $firstFontSize;
                letter-spacing: .6px;
                line-height: 23px;
                color: $grayColor;
                width: 50%;
            }
        }

        .dashboard {
            box-shadow: 0px 0px 70px rgba(0, 0, 0, 0.2);
            width: 90%;
            border-radius: 10px;
            margin: auto;
            margin-top: 50px;
            margin-bottom: 50px;

            .title {
                color: #424b5a;
            }

            .head {
                span {
                    color: #424b5a;
                }

                .addbtn {
                    background-color: #424b5a;
                    color: #e2e8fa;
                    font-size: $firstFontSize;
                    padding: 8px 12px;
                }
            }

            .laptop {
                table {
                    width: 100%;
                    border: 1px solid $grayColor;

                    tr {
                        border: 1px solid $grayColor;
                        text-align: center;

                        th {
                            border: 1px solid $grayColor;
                            padding: 5px;
                            color: #424b5a;
                        }
                    }

                    .editbtn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        color: #424b5a;
                        font-size: $firstFontSize;
                        padding: 8px 2px;
                    }

                    .deletebtn {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background-color: transparent;
                        color: #b91c1c;
                        font-size: $firstFontSize;
                        padding: 8px 2px;
                    }
                }
            }

            .mobile {
                border: 1px solid $grayColor;
                margin-top: 10px;
                padding: 20px;
                display: none;

                h3 {
                    font-size: 25px;
                    color: $grayColor;
                }

                p {
                    color: $grayColor;
                }

                .edit {
                    button {
                        background-color: transparent;
                        color: #424b5a;
                    }
                }

                .delete {
                    button {
                        background-color: transparent;
                        color: #b91c1c;
                    }
                }
            }
        }
    }
}

.dark {
    .dashboardpage {
        .heading {
            background: $darkColor;
            border-bottom: 1px solid;


            a {
                .svg-ic {
                    color: $lightColor;
                    border: 1px solid;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;

                    svg path {
                        fill: $lightColor;
                    }
                }

                span {
                    font-size: $secondFontSize;
                    letter-spacing: .6px;
                    letter-spacing: .6px;
                    color: $lightColor;
                }
            }

            h5 {
                color: #0098ff;
            }

            span {
                font-size: $firstFontSize;
                letter-spacing: .6px;
                line-height: 23px;
                color: $grayColor;
                width: 50%;
            }
        }

        .dashboard {
            width: 90%;
            margin: auto;

            .title {
                color: $lightColor;
            }

            .head {
                span {
                    color: $lightColor;
                }

                .addbtn {
                    background-color: #424b5a;
                    color: #e2e8fa;
                    font-size: $firstFontSize;
                    padding: 8px 12px;
                }
            }

            table {
                width: 100%;
                border: 1px solid;

                tr {
                    border: 1px solid;
                    color: $grayColor;
                    text-align: center;


                    th {
                        text-align: center;
                        padding: 5px;
                        border: 1px solid;
                        color: $grayColor;
                    }
                }

                .editbtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    color: #424b5a;
                    font-size: $firstFontSize;
                    padding: 8px 2px;
                }

                .deletebtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: transparent;
                    color: #b91c1c;
                    font-size: $firstFontSize;
                    padding: 8px 2px;
                }
            }
        }
    }
}

.test{
    display: none;
}

@media(max-width: 768px) {
    .dashboardpage {
        .dashboard{
            .laptop {
                display: none;
            }
    
            .mobile{
                display: block !important;
            }
        }
        
    }

    .test{
        display: block;
    }
}
.productdetails {
    background: $darkColor;

    .heading {
        background-color: $darkColor;

        a {
            .svg-ic {
                border: 1px solid;
                border-radius: 50%;
                width: 34px;
                height: 34px;
            }

            span {
                font-size: $secondFontSize;
                letter-spacing: .6px;
                color: $lightColor;
            }
        }
    }

    .brand {
        background: $linearColor;
        color: $lightColor;
        font-size: $firstFontSize;
        padding: 3px 10px;
    }

    .stars {
        i {
            color: $lightColor;
        }
    }

    .title {
        color: $lightColor;
        font-size: 41px;
    }

    .type {
        color: $grayColor;
        font-size: $firstFontSize;
    }

    .description {
        color: $grayColor;
        font-size: $firstFontSize;
        width: 70%;
    }

    .price {
        color: $lightColor;

        h3 {
            font-size: 30px;
        }

        sup {
            font-size: 19px;
        }
    }

    .bottom {
        button {
            @include button;
            background: $linearColor;
            padding: 7px 30px;
            border: 0;

            &:hover {
                background: transparent;
                border: 1px solid;
            }
        }
    }

    .details {
        .image {
            img {
                padding: 50px;
                border: 1px solid;
                border-image: linear-gradient(to left, #0098ff 50%, #7b35c8) 1;
            }
        }
    }

    .reviewsec {
        label {
            color: $grayColor;
            font-size: $firstFontSize;
        }

        input,
        textarea {
            color: $grayColor;
            border: 1px solid;
            border-radius: 0 20px;
            padding: 9px 20px;
            outline: none;
            background-color: $darkColor;

            &::placeholder {
                font-size: $firstFontSize;
            }
        }

        button {
            @include button;
            background: $linearColor;
            border: none;

            &:hover {
                background: $linearColor;
            }

            svg path {
                fill: $lightColor;
            }
        }
    }
}

@media(max-width: 992px) {
    .productdetails .description {
        width: 100%;
    }
}
.hero {
    background: $darkColor;

    .left {
        .heading {
            background-color: #12103e;
            color: $lightColor;
            font-size: $firstFontSize;
            width: 60%;
            padding: 10px 15px;

            span:nth-child(1) {
                background: linear-gradient(90deg, #0098ff 0%, rgba(0, 152, 255, 0) 100%);
            }

            span:nth-child(2) {
                font-size: $secondFontSize ;
                letter-spacing: .8px;
            }
        }

        h2 {
            @include heading;
            font-size: 60px;
        }

        p {
            font-size: $secondFontSize;
            color: $grayColor;
            letter-spacing: .5px;
            width: 65%
        }

        .buttons {

            a {
                @include button;
            }

            .one {
                background: $linearColor;
                border: none;

                &:hover {
                    background: transparent;
                    border: 1px solid $grayColor;
                }
            }

            .two {
                color: $grayColor;

                &:hover {
                    background: $linearColor;
                    color: $lightColor;
                    border: none;
                }
            }
        }
    }

    .right {
        img {
            width: 100%;
            height: 310px;
        }
    }
}

@media(max-width: 576px) {
    .hero {
        .left {
            .heading {
                span:nth-child(2) {
                    font-size: 14px !important;

                    a {
                        display: none;
                    }
                }
            }

            h2 {
                font-size: 40px !important;
            }
        }

        .right img {
            display: none;
        }
    }
}

@media(max-width: 768px) {
    .hero {
        .left .heading {
            span {
                font-size: $secondFontSize !important;
            }
        }
    }
}

@media(max-width: 992px) {
    .hero {
        .left {
            .heading {
                width: 100% !important;

                span:nth-child(1) {
                    font-size: 10px;
                }

                span:nth-child(2) {
                    font-size: 10px;
                }
            }

            p {
                width: 100%;
            }

            .buttons a {
                padding: 10px 15px;
            }
        }
    }
}

@media(max-width: 1400px) {
    .hero {
        .left .heading {
            width: 75%;
        }
    }
}


.slider {
    background: $darkColor;

    .heading {
        h2 {
            color: $lightColor;
            font-size: 35px;
        }

        a {
            @include button;
            color: $grayColor;

            &:hover {
                background: $linearColor;
                color: $lightColor;
                border: none;
            }
        }
    }

    .carousel {
        @include carousel;

        .title {
            a {
                color: $lightColor;
            }
        }

        .newprice {
            color: $lightColor;
        }
    }
}

@media(max-width: 375px) {
    .slider .heading {
        h2 {
            font-size: 28px !important;
        }
    }
}

@media(max-width: 576px) {
    .slider .carousel {
        .footer {
            .price {
                .newprice {
                    font-size: 22px !important;

                    sup {
                        font-size: 12px !important;
                    }
                }

                .oldprice {
                    font-size: $secondFontSize !important;
                }
            }

            button {
                @include button;
                padding: 7px 30px !important;
                border: none;

                &:hover {
                    border: 1px solid $grayColor;
                    color: $grayColor;
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .slider {
        .heading {
            h2 {
                font-size: 32px
            }

            a {
                padding: 10px 25px
            }
        }

        .carousel {
            .title {
                a {
                    font-size: 19px;
                }
            }
        }
    }
}

@media(max-width: 1200px) {
    .slider .carousel .title a {
        font-size: 18px;
    }
}

@media(max-width: 1400px) {
    .slider .carousel {
        .footer {
            .price {
                .newprice {
                    font-size: 15px;

                    sup {
                        font-size: 10px;
                    }
                }

                .oldprice {
                    font-size: 11px;
                }
            }

            button {
                @include button;
                padding: 7px 18px;
                border: none;
                font-size: 11px;

                &:hover {
                    border: 1px solid $grayColor;
                    color: $grayColor;
                }
            }
        }
    }
}


.light {
    .categories {
        .heading {
            @include heading;

            h2 {
                color: $darkColor;
                font-size: 35px;
            }
        }

        .buttons {
            a {
                width: 73%;
                @include button;
                color: $grayColor;
                padding: 10px 33px;
                font-size: 12px;

                button {
                    background: transparent;
                    color: $grayColor;
                }

                img {
                    filter: invert(.6);
                }
            }
        }

        .carousel {
            @include carousel;
        }
    }
}

.dark {
    .categories {
        .heading h2 {
            color: $lightColor;
        }

        .buttons {
            a {
                border: 1px solid $lightColor !important;

                button {
                    color: $lightColor;
                }

                img {
                    filter: invert(0);
                }
            }
        }

        .carousel {
            .title a {
                color: $lightColor;
            }

            .footer .price .newprice {
                color: $lightColor;
            }
        }
    }
}

@media(max-width: 1400px) {
    .categories .carousel {
        .title a {
            font-size: 18px !important;
        }

        .footer button {
            @include button;
            padding: 7px 18px !important;
            border: none;
            font-size: 11px !important;

            &:hover {
                border: 1px solid $grayColor;
                color: $grayColor;
            }
        }
    }
}

@media(max-width: 1200px) {
    .categories {

        .buttons {
            a {
                width: 85% !important;
                font-size: 11px !important;
            }
        }

        .carousel {

            .title a {
                font-size: 15px !important;
            }

            img {
                width: 180px !important;
            }

            .action {
                position: absolute;
                right: 15% !important;
            }

            .footer {
                .price {
                    .newprice {
                        font-size: 15px !important;
                    }

                    .oldprice {
                        font-size: 10px !important;
                    }
                }

                button {
                    padding: 8px 12px !important;
                }
            }
        }
    }
}

@media(max-width:768px) {
    .categories .buttons a {
        width: 100% !important;
    }
}


.light {
    .subscribe {
        span {
            font-size: $firstFontSize;
            background: $linearColor;
            color: $lightColor;
            opacity: 62%;
            padding: 6px
        }

        h2 {
            width: 77%;
            @include heading;
            color: $darkColor;
        }

        p {
            color: $grayColor;
            font-size: $firstFontSize;
            width: 80%;
        }

        .input-group {
            width: 75%;

            input {
                color: $grayColor;
                border: 1px solid $grayColor;
                border-radius: 0 15px;

                &::placeholder {
                    font-size: $secondFontSize;
                }

                &:focus {
                    box-shadow: none !important;
                }
            }
        }

        button {
            background: $linearColor;
            font-size: $secondFontSize;
            color: $lightColor;
            border-radius: 0 15px;
        }
    }
}

.dark {
    .subscribe {
        h2 {
            color: $lightColor;
        }

        .input-group input {
            background: transparent;

            &::placeholder {
                color: $grayColor;
            }

            &:focus {
                box-shadow: none !important;
            }
        }
    }
}

@media(max-width: 992px) {
    .subscribe {

        h2,
        p,
        .input-group {
            width: 100% !important;
        }

        img {
            height: 300px;
        }
    }
}


.light {
    .about-shop {
        .heading {
            h2 {
                font-size: 35px;
            }

            p {
                font-size: $firstFontSize;
                color: $grayColor;
            }
        }

        .cards {
            .borders {
                border: 1px solid #7b35c8;
                border-radius: 0 17px;
                background: linear-gradient(148deg, #0198ff2e, #0000);

                h2 {
                    @include textLinear;
                    font-size: 41px;
                }

                h4 {
                    font-size: 26px;
                }

                span {
                    display: block;
                    font-size: $firstFontSize;
                    color: $grayColor;
                }
            }
        }
    }
}

.dark {
    .about-shop {
        .heading h2 {
            color: $lightColor;
        }

        .cards .borders h4 {
            color: $lightColor;
        }
    }
}


.blog-sec {
    background-color: $darkColor;

    .heading {
        h2 {
            font-size: 41px;
            @include textLinear;
            width: 26%;
        }

        p {
            color: $grayColor;
            font-size: $firstFontSize;
        }

        a {
            @include button;
            color: $grayColor;

            &:hover {
                background: $linearColor;
                color: $lightColor;
                border: none;

                svg path {
                    fill: $lightColor;
                }
            }
        }
    }

    .blog {
        @include blog;

        .blogcard {
            .card-title {
                color: $lightColor;
            }

            .author {
                color: $grayColor;
            }

            .card-text {
                display: none
            }
        }
    }
}

@media(max-width: 992px) {
    .blog-sec {
        .heading {
            h2 {
                width: 100%;
                font-size: 36px;
            }

            p {
                display: none;
            }
        }

        .blog .blogcard .card-title {
            font-size: 17.3px;
        }
    }
}


.light {
    .products {
        .heading {
            h2 {
                font-size: 41px;
                width: 26%;
            }

            p {
                color: $grayColor;
                font-size: $firstFontSize;
            }

            a {
                @include button;
                color: $grayColor;

                &:hover {
                    background: $linearColor;
                    color: $lightColor;
                    border: none;

                    svg path {
                        fill: $lightColor;
                    }
                }
            }
        }

        .carousel {
            @include carousel;
        }
    }
}

.dark {
    .products {
        .heading h2 {
            color: $lightColor;
        }

        .carousel {
            .title a {
                color: $lightColor;
            }

            .footer .price .newprice {
                color: $lightColor;
            }
        }
    }
}

@media(max-width: 1200px) {
    .products .carousel {
        .title a {
            font-size: 17px !important;
        }

        .footer {
            .price {
                .newprice {
                    font-size: 17px !important;
                }
            }

            button {
                font-size: 12px;
                padding: 7px 19px !important;
            }
        }

        .action {
            right: 18% !important;
        }
    }
}

@media(max-width: 992px) {
    .products {
        .heading {
            h2 {
                width: 100% !important;
                font-size: 36px !important;
            }

            p {
                display: none;
            }
        }
    }
}


.light {
    .testimonials {
        .heading {
            h2 {
                font-size: 35px;
            }
        }

        .slider-container {
            .slick-slide {
                margin-left: 10px;
            }

            .cards {
                border: 1px solid #7b35c8;
                border-radius: 0 15px;
                background: linear-gradient(148deg, #0198ff2e, #0000);

                .img {
                    width: 30%;

                    img {
                        width: 100%;
                    }
                }

                .content {
                    h3 {
                        font-size: 24px;
                    }

                    p {
                        font-size: $secondFontSize;
                        width: 72%;
                    }

                    .content-bottom {
                        .info h6 {
                            font-size: $secondFontSize;
                        }

                        .star span {
                            font-size: $secondFontSize;

                            b {
                                color: #0098ff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.dark {
    .testimonials {
        .heading {
            h2 {
                color: $lightColor;
            }
        }

        .slider-container {
            .slick-slide {
                margin-left: 10px;
            }

            .cards .content {
                h3 {
                    color: $lightColor;
                }

                p {
                    color: $grayColor;
                }

                .content-bottom {
                    .info h6 {
                        color: $grayColor;
                    }

                    .star span {
                        color: $grayColor;

                        b {
                            color: #0098ff;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 992px) {
    .testimonials {
        display: none;
    }
}


.hardware {
    background-color: $darkColor;
    width: 100%;
    overflow-x: hidden;

    .heading {
        h2 {
            font-size: 41px;
            width: 26%;
            color: $lightColor;
        }

        p {
            color: $grayColor;
            font-size: $secondFontSize;
        }

        a {
            @include button;
            color: $grayColor;

            &:hover {
                background: $linearColor;
                color: $lightColor;
                border: none;

                svg path {
                    fill: $lightColor;
                }
            }
        }
    }

    .cards {
        border: double 2px transparent;
        border-radius: 0 15px;
        background-image: linear-gradient(#000, #000), radial-gradient(circle at top left, #0098ff 50%, #7b35c8);
        background-origin: border-box;
        background-clip: padding-box, border-box;

        h3 {
            color: $lightColor;
            font-size: 26px;
        }

        p {
            color: $grayColor;
            font-size: $firstFontSize;
            width: 90%;
        }

        span {
            color: $lightColor;
            font-size: 20px;

            sup {
                font-size: 13px;
            }
        }
    }
}

@media(max-width: 992px) {
    .hardware {
        margin-top: 40px;

        .heading {
            h2 {
                width: 100%;
                font-size: 36px;
            }

            p {
                display: none;
            }
        }
    }
}

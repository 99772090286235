header {
    background: $darkColor;
    border-bottom: 1px solid;

    position: fixed;
    width: 100%;
    top: 0;
    z-index: 2000;

    .navbar-left {
        .title {
            color: $lightColor;
            font-size: 15px;
        }
    }

    .navbar-right {
        position: relative;

        .admin {
            .dropdown {
                @include dropdown;
                position: absolute;
                left: -80px;
            }

            li:hover .dropdown {
                visibility: visible;
            }

            .hello-user {
                font-size: $firstFontSize;
                color: rgb(17, 157, 162);
                border: 1px solid;
            }

            .logoutbtn {
                background: transparent;
                color: red;
                font-size: $firstFontSize;
            }
        }

        .language,
        .mode {
            button:focus {
                border: none !important;
            }
        }

        .cart {
            font-size: $secondFontSize;

            .cart-menu {
                background-color: $lightColor;
                z-index: 100;
                position: fixed;
                top: 0;
                right: 0;
                width: 29%;
                height: 100% !important;
                transform: translate(100%);
                transition: .5s;
                overflow-y: scroll;

                &.open {
                    transform: translate(0);
                }

                .heading {
                    background-color: #0098ff;

                    .close-btn {
                        background: transparent;
                    }

                    h4 {
                        font-size: 22px;
                    }

                    span {
                        font-size: 14px;
                        letter-spacing: .8px;
                    }
                }

                .body {
                    height: 50vh;
                    overflow-y: auto;

                    .products {
                        border: 1px solid rgb(76 62 62 / 15%);

                        .content {
                            h5 {
                                font-size: 16px;
                            }

                            button {
                                background: transparent;
                            }
                        }
                    }
                }

                .footer {
                    border-top: 1px solid rgb(76 62 62 / 15%);
                    
                    .head {
                        span {
                            font-size: $firstFontSize;
                            color: $grayColor;
                        }
                    }

                    .input-group {
                        input {
                            border-radius: 0 20px;

                            &::placeholder {
                                font-size: $secondFontSize;
                                letter-spacing: .5px;
                            }

                            &:focus {
                                box-shadow: none;
                                border-color: #dedcdc;
                            }
                        }

                        button {
                            border-radius: 0 10px;
                            font-size: $firstFontSize;
                            background-color: $grayColor;
                        }
                    }

                    .subtotal {
                        h3 {
                            font-size: 18px;
                        }
                    }

                    .footer {
                        border: 0;

                        .button {
                            @include button;
                            background: $linearColor;
                            border: none;

                            &:hover {
                                border: 1px solid $grayColor;
                                color: $grayColor;

                                svg path {
                                    fill: $grayColor;
                                }
                            }
                        }
                    }


                }


            }
        }
    }

    .menu {
        position: absolute;
        right: 0;

        .language,
        .mode {
            display: none;

            button:focus {
                border: none;
            }
        }

        .open-btn {
            display: none;
            color: $lightColor;
            background: transparent;
        }

        .offcanvas-menu {
            overflow-y: scroll;
            z-index: 100;
            position: fixed;
            top: 0;
            right: 0;
            width: 100%;
            height: 100vh !important;
            transform: translate(100%);
            transition: .5s;

            &.open {
                transform: translate(0);
            }

            &.close {
                transform: translate(100%);
            }

            .heading {
                background: $backgroundColor;

                .close-btn {
                    color: $lightColor;
                    background: transparent;
                }
            }

            .body {
                background-color: $lightColor;

                .nav-item {
                    a {
                        color: $darkColor;
                        transition: .4s;

                        &:hover {
                            color: #0098ff;
                        }
                    }
                }

                .accordion {

                    .user {
                        .hello-user {
                            font-size: $firstFontSize;
                            color: rgb(17, 157, 162);
                            border: 1px solid;
                        }

                        .logoutbtn {
                            background: transparent;
                            color: red;
                            font-size: $firstFontSize;
                        }

                        a {
                            color: $darkColor;
                            transition: .4s;

                            &:hover {
                                color: #0098ff;
                            }
                        }
                    }

                    .accordion-item .accordion-header button {
                        &:focus {
                            box-shadow: none !important;
                            background-color: transparent;
                            border-bottom: 1px solid #ccc;
                        }
                    }
                }

            }
        }
    }
}

@media(max-width:576px) {
    header {
        .navbar .navbar-right {
            display: none !important;
        }

        .menu {
            .mode {
                display: block !important;
            }

            .language {
                display: block !important;
            }

            .open-btn {
                display: block !important;
            }

            .offcanvas-menu .accordion {
                display: block !important;
            }
        }
    }
}

@media(max-width:992px) {
    header {
        .navbar {
            .navbar-left {
                display: none !important;
            }

            .navbar-right {
                margin-right: 50px;

                .cart .cart-menu {
                    width: 55%;

                }
            }
        }

        .menu {
            .open-btn {
                display: block !important;
            }

            .offcanvas-menu .accordion {
                display: none;
            }
        }
    }
}

.bottom-header {
    background: $darkColor;
    border-bottom: 1px solid;
    margin-top: 72.3px;

    .left {
        span {
            color: $lightColor;
            font-size: $firstFontSize;
            letter-spacing: .8px;

            a {
                color: $lightColor;
                text-decoration: underline;
            }
        }
    }

    .search {
        position: relative;
        z-index: 1000;

        .input-group {
            a {
                border-color: #cccccc;
                border-radius: 0 20px;
                background: transparent;
                padding: 13px 15px;
                border-right: none;

                i {
                    color: #cccccc;
                }
            }

            input {
                border: 0.5px solid #cccccc;
                color: #cccccc;
                background: transparent;
                border-radius: 0 20px;
                border-left: none;
                padding: 9px 15px;

                &::placeholder {
                    color: $grayColor;
                    font-size: $firstFontSize;
                    letter-spacing: .6px;
                }

                &:focus {
                    box-shadow: none !important;
                }
            }
        }

        .list {
            height: 44.5vh;
            overflow-y: auto;
            position: absolute;

            .list-group-item {
                border: 7px solid blue;
                background-color: #cccccc;
                border: 1px solid $grayColor;


                .content {
                    color: $grayColor;

                    .description {
                        font-size: 11px;
                    }

                    .type i {
                        font-size: 11px;
                    }
                }

                &.close-search {
                    display: none !important;
                }
            }
        }

    }

    .right {
        a {
            color: $lightColor;
            font-size: $firstFontSize;
            letter-spacing: .8px;
            text-decoration: underline;
        }
    }
}

@media(max-width:768px) {
    .bottom-header .search {

        .input-group,
        .list-group-item {
            width: 100% !important;
            transform: translate(0) !important;
        }
    }
}

@media(max-width:992px) {
    .bottom-header {

        .left,
        .right {
            display: none !important;
        }

        .search {

            .input-group,
            .list-group-item {
                width: 500px;
                transform: translate(-70px);
            }
        }
    }
}

@media(max-width:576px) {
    .bottom-header {
        margin-top: 65.9px;
    }
}

.footers {
    border-top: 1px solid;
    background-color: $darkColor;

    footer {
        .left {
            color: $lightColor;

            h3 {
                @include heading;
                font-size: 26px;
                width: 84%;
            }

            p {
                font-size: $firstFontSize;
                letter-spacing: .3px;
                width: 82%;
            }
        }

        .center * {
            color: $lightColor;
            font-size: $firstFontSize;

            h4 {
                letter-spacing: 1px;
            }

            a {
                color: $lightColor !important;
                font-size: $firstFontSize !important;
                transition: .4s;

                &:hover {
                    opacity: .3;
                }
            }
        }

        .right {
            color: $lightColor;

            h4 {
                font-size: $firstFontSize;
                letter-spacing: 1px;
            }

            a {
                background-color: $lightColor;

                i {
                    color: $darkColor;
                }

                &:hover {
                    background: $linearColor;

                    i {
                        color: $lightColor;
                    }
                }
            }

        }
    }

    .footer-bottom {
        background-color: $darkColor;
        border-top: 1px solid;
        padding-top: 20px;

        p {
            color: $lightColor;
            font-size: $firstFontSize;
        }
    }
}

@media(max-width:992px) {
    .footers footer .right a:nth-child(3) {
        display: none !important;
    }
}
.light {
    .cartpage {
        .heading {

            a {
                .svg-ic {
                    color: $darkColor;
                    border: 1px solid;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;

                    svg path {
                        fill: $darkColor;
                    }
                }

                span {
                    font-size: $secondFontSize;
                    letter-spacing: .6px;
                    color: $darkColor;
                }
            }
        }

        .laptop {

            table {
                width: 100%;

                thead tr {
                    background-color: #ccc;

                    th {
                        font-size: $firstFontSize;
                        border: 1px solid $grayColor;
                        background-color: #cccccc;
                        color: $grayColor;
                    }
                }

                tbody tr {
                    border: 1px solid $grayColor;

                    td {
                        font-size: $firstFontSize;
                        color: $grayColor;
                    }

                    .quantity {
                        border: 1px solid rgb(76 62 62 / 15%);
                        max-width: 100px;
                        height: 30px;

                        button {
                            background: transparent;

                            svg path {
                                fill: $grayColor;
                            }
                        }
                    }

                    .total {
                        button {
                            background: transparent;

                            svg path {
                                fill: red;
                            }
                        }
                    }
                }
            }
        }

        .empty-mobile {
            display: none;
        }

        .mobile {
            display: none;

            .content {
                border: 1px solid $grayColor;
                margin-top: 10px;
                padding: 20px;

                .product {
                    h3 {
                        color: $grayColor;
                        font-size: 20px;
                    }
                }

                .name {
                    h3 {
                        color: $grayColor;
                        font-size: 20px;
                    }

                    p {
                        font-size: 13px;
                        color: $grayColor;
                    }
                }

                .cart-price {
                    h3 {
                        color: $grayColor;
                        font-size: 20px;
                    }

                    span {
                        font-size: 13px;
                        color: $grayColor;
                    }
                }

                .quantity {
                    h3 {
                        color: $grayColor;
                        font-size: 20px;
                    }


                    .buttons {
                        border: 1px solid rgb(76 62 62 / 15%);

                        button {
                            max-width: 100px;
                            height: 30px;
                            background: transparent;

                            svg path {
                                fill: $grayColor;
                            }
                        }
                    }

                }

                .total {
                    h3 {
                        color: $grayColor;
                        font-size: 20px;
                    }

                    span {
                        font-size: $firstFontSize;
                        color: $grayColor;
                    }

                    button {
                        background: transparent;

                        svg path {
                            fill: red;
                        }
                    }
                }
            }


        }

        .border {
            border-radius: 0 20px;
            padding: 30px 15px;

            h3 {
                font-size: 26px;
                color: $grayColor;
            }

            span {
                font-size: 20px;
                @include textLinear;
            }

            p {
                font-size: $firstFontSize;
                width: 95%;
                color: $grayColor;
            }

            a {
                @include button;
                background: $linearColor;
                border: none;
                padding: 10px 40px;
                transition: .6s;

                &:hover {
                    color: $grayColor;
                    border: 1px solid $grayColor;
                }
            }
        }
    }
}

.dark {
    .cartpage {
        .heading {

            a {
                .svg-ic {
                    color: $lightColor;
                    border: 1px solid;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;

                    svg path {
                        fill: $lightColor;
                    }
                }

                span {
                    font-size: $secondFontSize;
                    letter-spacing: .6px;
                    color: $lightColor;
                }

            }

            p {
                color: $lightColor;
            }
        }

        .empty-laptop,
        .empty-mobile {
            p {
                color: #ccc;
            }
        }

        .laptop {
            table {
                width: 100%;
                border: 1px solid $grayColor;

                thead tr {
                    th {
                        font-size: $firstFontSize;
                        color: $lightColor;
                        border: 1px solid;
                    }

                }

                tbody tr {
                    border: 1px solid $lightColor;

                    td {
                        font-size: $firstFontSize;
                        color: $lightColor;
                    }

                    .quantity {
                        border: 1px solid rgb(76 62 62 / 15%);
                        max-width: 100px;
                        height: 30px;

                        button {
                            background: transparent;

                            svg path {
                                fill: $lightColor;
                            }
                        }
                    }

                    .total {
                        button {
                            background: transparent;

                            svg path {
                                fill: red;
                            }
                        }
                    }
                }
            }
        }

        .border {
            border-radius: 0 20px;
            padding: 30px 15px;

            h3 {
                font-size: 26px;
                color: $lightColor;
            }

            span {
                font-size: 20px;
                @include textLinear;
            }

            p {
                font-size: $firstFontSize;
                width: 95%;
                color: $grayColor;
            }

            a {
                @include button;
                background: $linearColor;
                border: none;
                padding: 10px 40px;
                transition: .6s;

                &:hover {
                    color: $grayColor;
                    border: 1px solid $grayColor;
                }
            }
        }
    }
}


@media (max-width: 768px) {
    .light {
        .cartpage {
            .empty-laptop {
                display: none;
            }

            .laptop {
                display: none;
            }

            .empty-mobile {
                display: block;
            }

            .mobile {
                display: block;
            }
        }
    }
}
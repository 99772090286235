.light {
    .wishlistpage {
        .heading {
            background-color: $darkColor;

            a {
                .svg-ic {
                    color: $lightColor;
                    border: 1px solid;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;

                    svg path {
                        fill: $lightColor;
                    }
                }

                span {
                    font-size: $secondFontSize;
                    letter-spacing: .6px;
                    letter-spacing: .6px;
                    color: $lightColor;
                }
            }

            h5 {
                color: #0098ff;
            }

            p {
                font-size: $firstFontSize;
                letter-spacing: .6px;
                line-height: 23px;
                color: $grayColor;
                width: 50%;
            }
        }

        .wishlist {
            .title h2 {
                font-size: 41px;
            }

            .empty-laptop {
                table {
                    border-collapse: collapse;
                    border-spacing: 0;
                    width: 100%;

                    thead th {
                        border: 1px solid $grayColor;
                        background-color: #cccccc;
                        color: $grayColor;
                    }
                }
            }

            .laptop {
                table {
                    border-collapse: collapse;
                    border-spacing: 0;
                    width: 100%;

                    thead th {
                        border: 1px solid $grayColor;
                        background-color: #cccccc;
                        color: $grayColor;
                    }

                    tbody tr {
                        border: 1px solid $grayColor;

                        .brand,
                        .title,
                        .price {
                            color: $grayColor;
                        }

                        .addbtn {
                            @include button;
                            background: $linearColor;
                            border: none;

                            &:hover {
                                background: $linearColor;
                            }
                        }

                        .removebtn {
                            background: transparent;
                            color: red;
                        }

                    }
                }
            }

            .empty-mobile {
                display: none;
            }

            .mobile {
                display: none;

                .content {
                    border: 1px solid $grayColor;
                    margin-top: 10px;
                    padding: 20px;

                    h3 {
                        font-size: 20px;
                        color: $grayColor;
                    }

                    .details {

                        p,
                        h2,
                        span {
                            font-size: $firstFontSize;
                            color: $grayColor;
                        }
                    }

                    .add-product {
                        button {
                            @include button;
                            background: $linearColor;
                            border: none;
                            padding: 8px 22px;

                            &:hover {
                                background: $linearColor;
                            }
                        }
                    }

                    .delete {
                        button {
                            background: transparent;
                            color: red;
                        }
                    }
                }


            }
        }
    }
}

.dark {
    .wishlistpage {
        background-color: $darkColor;

        .heading {
            background-color: $darkColor;
            border-bottom: 1px solid;

            a {
                .svg-ic {
                    color: $lightColor;
                    border: 1px solid;
                    border-radius: 50%;
                    width: 34px;
                    height: 34px;

                    svg path {
                        fill: $lightColor;
                    }
                }

                span {
                    font-size: $secondFontSize;
                    letter-spacing: .6px;
                    letter-spacing: .6px;
                    color: $lightColor;
                }
            }

            h5 {
                color: #0098ff;
            }

            p {
                font-size: $firstFontSize;
                letter-spacing: .6px;
                line-height: 23px;
                color: $grayColor;
                width: 50%;
            }
        }

        .wishlist {
            .title h2 {
                font-size: 41px;
                color: $lightColor;
            }

            .laptop {
                table {
                    width: 100%;

                    border: 1px solid #ccc;

                    thead th {
                        border: 1px solid;
                        background-color: $darkColor;
                        color: $grayColor;
                    }

                    tbody tr {
                        border: 1px solid $grayColor;

                        background-color: $darkColor;

                        .brand,
                        .title,
                        .price {
                            color: $grayColor;
                        }

                        .addbtn {
                            @include button;
                            background: $linearColor;
                            border: none;

                            &:hover {
                                background: $linearColor;
                            }
                        }

                        .removebtn {
                            background: transparent;
                            color: red;
                        }

                    }
                }
            }

            .empty-mobile {
                color: #ccc;
            }
        }
    }
}

@media (max-width: 768px) {
    .light {
        .wishlistpage .wishlist {
            .empty-laptop {
                display: none;
            }

            .laptop {
                display: none;
            }

            .empty-mobile {
                display: block;
            }

            .mobile {
                display: block;
            }
        }
    }
}

@media (max-width: 992px) {
    .light {
        .wishlistpage {
            .heading {
                p {
                    width: 100%;
                }
            }
        }
    }
}
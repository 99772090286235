@mixin button {
    border: 1px solid $grayColor;
    border-radius: 0 15px;
    padding: 12px 40px;
    font-size: $firstFontSize;
    color: $lightColor;

    &:hover {
        background: transparent;
    }
}

@mixin heading {
    font-size: 41px;
    color: $lightColor;

    b {
        background: $linearColor;
        background-clip: text;
        color: transparent;
    }
}

@mixin carousel {
    width: 95%;
    position: relative;
    border: 1px solid #0098ff;
    border-radius: 0 20px;
    background: linear-gradient(148deg, #0198ff2e, #0000);

    .title {
        a {
            color: $darkColor;
            font-size: 20px;
        }

        span {
            color: $grayColor;
            font-size: $secondFontSize;
        }
    }

    .new {
        position: absolute;
        top: 90px;
        font-size: $secondFontSize;
        color: $lightColor;
        background: linear-gradient(90deg, #0098ff 0%, rgba(0, 152, 255, 0) 100%);
    }

    .footer {
        .price {
            .newprice {
                font-size: 22px;

                sup {
                    font-size: 12px;
                }
            }

            .oldprice {
                font-size: $secondFontSize;
                color: $grayColor;
            }
        }

        button {
            @include button;
            background: $linearColor;
            padding: 7px 20px;
            border: none;
            font-size: $firstFontSize;

            &:hover {
                border: 1px solid $grayColor;
                color: $grayColor;
            }
        }
    }

    .action {
        position: absolute;
        top: 50%;
        right: 22%;
        border-radius: 0 12px;
        color: $lightColor;
        background: $linearColor;
        opacity: 0;
        transition: .6s;

        button {
            background: transparent;
            color: $lightColor;
        }
    }

    &:hover .action {
        opacity: 1;
    }
}

@mixin textLinear {
    background: -webkit-linear-gradient(93.25deg, #0098ff 4.45%, #7b35c8 93.88%);
    background-clip: text;
    color: transparent;
}

@mixin link {
    color: $darkColor;

    &:hover {
        text-decoration: underline;
    }
}

@mixin dropdown {
    background-color: $lightColor;
    padding: 20px;
    position: absolute;
    visibility: hidden;
    transition: .2s;
    margin-top: 27px;

    a {
        color: $darkColor;
        font-size: 14px;

        &:hover {
            border-bottom: 1px solid $darkColor;
        }
    }
}

@mixin blog {
    .blogcard {
        img {
            border-radius: 0 17px;
            border: 2px solid #0098ff;
        }

        .card-title {
            font-size: 22px;
        }

        .card-text {
            color: $grayColor;
            font-size: $firstFontSize;
        }

        .btn {
            @include button;
            background: $linearColor;
            border: none;

            &:hover {
                color: $grayColor;
                border: 1px solid $grayColor;
            }
        }
    }
}
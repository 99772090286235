body {
    font-family: Outfit, sans-serif;
    margin: 0;
    padding: 0;
    overflow-x: hidden
}

li {
    list-style-type: none;
}

a {
    text-decoration: none;
}


button{
    border: none;
}
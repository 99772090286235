.light {
    .createaccount {
        color: $grayColor;
        font-size: $firstFontSize;

        .heading {
            h2 {
                color: $darkColor;
                font-size: 41px;
            }
        }

        .login-form {
            border: 1px solid #cccccc;
            width: 50%;
            margin: auto;

            .head {
                border-bottom: 1px solid #cccccc;

                h4 {
                    font-size: 18px;
                }
            }

            .form-container {
                border-bottom: 1px solid #cccccc;

                .form-group {

                    input {
                        border: 1px solid #cccccc;
                        border-radius: 0 20px;
                        padding: 12px 20px;
                        outline: none;
                    }
                }
            }

            .form-bottom {
                border-bottom: 1px solid #cccccc;

                .form-group {

                    input {
                        border: 1px solid #cccccc;
                        border-radius: 0 20px;
                        padding: 12px 20px;
                        outline: none;
                    }
                }
            }

            .form-footer {
                a {
                    color: red;
                }

                button {
                    @include button;
                    background: $linearColor;
                    border: none;

                    &:hover {
                        background: $linearColor;
                    }

                    svg path {
                        fill: $lightColor;
                    }
                }
            }
        }
    }
}

.dark {
    .createaccount {
        color: $grayColor;
        font-size: $firstFontSize;

        .heading {
            h2 {
                color: $lightColor;
                font-size: 41px;
            }
        }

        .login-form {
            border: 1px solid #cccccc;
            width: 50%;
            margin: auto;

            .head {
                border-bottom: 1px solid #cccccc;

                h4 {
                    font-size: 18px;
                }
            }

            .form-container {
                border-bottom: 1px solid #cccccc;

                .form-group {

                    input {
                        background-color: $darkColor;
                        color: #cccccc;
                        border: 1px solid #cccccc;
                        border-radius: 0 20px;
                        padding: 12px 20px;
                        outline: none;
                    }

                    label {
                        color: $lightColor;
                    }
                }
            }

            .form-bottom {
                border-bottom: 1px solid #cccccc;

                .form-group {

                    input {
                        background-color: $darkColor;
                        color: #cccccc;
                        border: 1px solid #cccccc;
                        border-radius: 0 20px;
                        padding: 12px 20px;
                        outline: none;
                    }

                    label {
                        color: $lightColor;
                    }
                }
            }

            .form-footer {
                a {
                    color: red;
                }

                button {
                    @include button;
                    background: $linearColor;
                    border: none;

                    &:hover {
                        background: $linearColor;
                    }

                    svg path {
                        fill: $lightColor;
                    }
                }
            }
        }
    }
}

@media(max-width: 992px) {
    .light {
        .createaccount .login-form {
            width: 100%;

            .form-footer {
                button {
                    padding: 10px 25px;
                    font-size: 12px;
                }
            }
        }
    }
}